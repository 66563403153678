<template>
  <v-app class="security">
    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="12">
            <v-form
              @submit.prevent="login(form)"
              class="login"
              autocomplete="off"
            >
              <v-card max-width="700" elevation="1" class="mx-auto" rounded="0">
                <v-row no-gutters>
                  <v-col cols="12" md="5" class="py-0">
                    <v-card
                      elevation="0"
                      rounded="0"
                      class="fill-height px-0 py-4"
                      color="primary"
                    >
                      <div
                        class="
                          d-flex
                          fill-height
                          justify-center
                          align-center
                          flex-column
                        "
                      >
                        <img
                          width="65"
                          :src="require('@/assets/img/logo.png')"
                          class="mb-4"
                        />
                        <div class="text-h5 white--text">Omnicanal</div>
                        <div class="text-body-1 white--text">
                          {{ $t("app.description") }}
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-card elevation="0" class="py-4">
                      <v-card-text class="mb-0 px-12">
                        <div class="text-h5 black--text">
                          {{ $i18n.t("security.title") }}
                        </div>
                        <div>
                          <span class="body-2 grey--text text--darken-1">{{
                            $i18n.t("security.description")
                          }}</span>
                        </div>
                      </v-card-text>
                      <v-card-text class="mb-0 pb-1 px-12">
                        <v-text-field
                          append-icon="mdi-account-circle-outline"
                          name="username"
                          v-model="form.username"
                          :label="$i18n.t('security.form.username.title')"
                          type="text"
                          autocomplete="off"
                          class="font-weight-medium text-body-1"
                          outlined
                          dense
                        ></v-text-field>
                        <v-text-field
                          append-icon="mdi-lock-outline"
                          name="password"
                          v-model="form.password"
                          :label="$i18n.t('security.form.password.title')"
                          id="password"
                          type="password"
                          autocomplete="off"
                          class="font-weight-medium text-body-1"
                          outlined
                          dense
                        ></v-text-field>
                        <v-btn
                          color="primary"
                          :loading="loading"
                          type="submit"
                          class="mt-2 mb-5"
                          >{{ $i18n.t("security.form.submit") }}</v-btn
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                max-width="340"
                elevation="0"
                class="py-0 my-0 mx-auto"
                color="rgba(255,255,255,0)"
              >
                <v-card-text class="text-center py-3 my-0">
                  <span
                    class="
                      font-weight-medium
                      grey--text
                      text--darken-1 text-body-2
                    "
                    >&copy; LMTGROUP OMNICANAL 2021</span
                  >
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
.security {
  background: #f4f6f9 !important;
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    loading: false,
    form: {
      username: null,
      password: null,
    },
  }),
  metaInfo() {
    return {
      title: this.$i18n.t("security.title"),
    };
  },
  methods: {
    async login(form) {
      let self = this;
      let redirect = this.$auth.redirect();
      let message = "security.form.bad_credentials";

      // hide old notification
      this.notify({ status: false });

      if (!this.form.username || !this.form.password) {
        this.notify({
          status: true,
          message: this.$i18n.t(message),
          right: false,
        });
        return;
      }

      this.loading = true;

      this.$auth
        .login({
          data: form,
          ignoreVueAuth: true,
          redirect: { name: redirect ? redirect.from.name : "home" },
        })
        .then(() => {
          this.$socket.open();
          self.loading = false;
        })
        .catch((error) => {
          self.loading = false;

          const status = error.response ? error.response.status : 500;

          if (status !== 401) {
            message = "error_occured";
          }

          self.notify({
            status: true,
            top: true,
            right: false,
            message: self.$i18n.t(message),
          });
        });
    },
    ...mapActions({
      notify: "notification/notify",
    }),
  },
};
</script>