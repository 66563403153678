<template>
  <login></login>
</template>

<script>
import Login from "./../../components/security/login.vue";
export default {
  created() {
    // clear permissions
    this.$permission.$clear();
  },
  data: () => ({}),
  components: {
    Login
  }
};
</script>